<template>
    <div>
        <el-form :model="discriInfo" ref="ruleForm" label-width="140px" class="demo-ruleForm">
            <el-form-item label="描述：" prop="page" class="pageSelect" require>
                <emoji-input palce="描述：添加其他详情" type="text" v-model="discriInfo.content"></emoji-input>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
    import emojiInput from '../../../createAd/components/emojiInput'
    export default {
        props:['discriMsg'],
        data () {
            return {
                discriInfo:{...this.discriMsg}
            }
        },
        components:{
            emojiInput
        },
        methods: {
            setMsg(){
                this.$emit('getDiscriMsg',this.discriInfo)
            },
        },
        watch: {
            discriMsg:{
                handler(){
                    this.discriInfo = {...this.discriMsg}
                },
                deep:true
            },
            discriInfo:{
                handler(){
                    this.setMsg()
                },
                deep:true
            }
        }
    }
</script>
<style lang="scss">
    
</style>