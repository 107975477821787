<template>
  <div>
    <div class="targetingItem">
      <p
        class="baseName"
        v-if="!isNotMix(nameList)"
      >
        <span class="lebel">名称：</span>
        <span>
          <el-button
            type="text"
            size="mini"
            @click="nameMixShow = true"
            >混合值查看及编辑</el-button
          >
        </span>
      </p>
      <el-row v-if="isNotMix(nameList)">
        <el-col :span="20">
          <name
            :label-width="labelWidth"
            :nameInfo="nameMsg"
            @getNameMsg="getNameMsg"
          ></name>
        </el-col>
        <el-col
          :span="2"
          v-if="currentSelect.length > 1"
          ><el-button
            type="text"
            size="mini"
            @click="nameMixShow = true"
            class="mixBtn"
            >混合值查看及编辑</el-button
          ></el-col
        >
      </el-row>
    </div>
    <div
      class="targetingItem"
      v-if="!show_mood"
    >
      <p
        class="baseName"
        v-if="!isNotMix(postIdList)"
      >
        <span class="lebel">帖子Id：</span>
        <span>
          <el-button
            type="text"
            size="mini"
            @click="postIdMixShow = true"
            >混合值查看及编辑</el-button
          >
        </span>
      </p>
      <el-row v-if="isNotMix(postIdList)">
        <el-col :span="20">
          <post-id
            :label-width="labelWidth"
            :postIdInfo="postIdMsg"
          ></post-id>
        </el-col>
        <el-col
          :span="2"
          v-if="currentSelect.length > 1"
          ><el-button
            type="text"
            size="mini"
            @click="postIdMixShow = true"
            class="mixBtn"
            >混合值查看及编辑</el-button
          ></el-col
        >
      </el-row>
    </div>
    <div class="targetingItem">
      <p
        class="baseName"
        v-if="!isNotMix(statusList)"
      >
        <span class="lebel">状态：</span>
        <span>
          <el-button
            type="text"
            size="mini"
            @click="statusMixShow = true"
            >混合值查看及编辑</el-button
          >
        </span>
      </p>
      <el-row v-if="isNotMix(statusList)">
        <el-col :span="20">
          <status
            :label-width="labelWidth"
            :statusInfo="statusMsg"
            @getStatusMsg="getStatusMsg"
          ></status>
        </el-col>
        <el-col
          :span="2"
          v-if="currentSelect.length > 1"
          ><el-button
            type="text"
            size="mini"
            @click="statusMixShow = true"
            class="mixBtn"
            >混合值查看及编辑</el-button
          ></el-col
        >
      </el-row>
    </div>
    <div class="targetingItem">
      <p
        class="baseName"
        v-if="!isNotMix(pageMsgList)"
      >
        <span class="lebel">主页：</span>
        <span>
          <el-button
            type="text"
            size="mini"
            @click="pageMixShow = true"
            >混合值编辑</el-button
          >
        </span>
      </p>
      <el-row v-if="isNotMix(pageMsgList)">
        <el-col :span="20">
          <page
            :label-width="labelWidth"
            :pageList="pageList"
            :pageInfo="pageMsg"
            @getPageMsg="getPageMsg"
            @getPages="getPages"
            :loadPages="loadPages"
            :objective="objective"
            :account="account"
            :targetUserId="targetUserId"
          ></page>
        </el-col>
        <!-- <el-col :span="2" v-if="currentSelect.length>1"><el-button type="text" size="mini"  @click="pageMixShow = true" class="mixBtn">混合值查看及编辑</el-button></el-col> -->
      </el-row>
    </div>
    <div class="targetingItem">
      <p class="baseName">
        <span class="lebel">广告样式：</span>
        <span>
          <el-button
            type="text"
            size="mini"
            @click="adTypeMixShow = true"
            >{{ currentSelect.length > 1 ? '编辑混合值' : '编辑创意类型' }}</el-button
          >
          <el-button
            type="text"
            size="mini"
            @click="setBaseData('adType')"
            >撤销修改</el-button
          >
        </span>
      </p>
    </div>
    <div
      class="targetingItem"
      v-if="!adTypesHasAssetFeed"
    >
      <p
        class="baseName"
        v-if="!isNotMix(messageList)"
      >
        <span class="lebel">正文：</span>
        <span>
          <el-button
            type="text"
            size="mini"
            @click="messageMixShow = true"
            >混合值查看及编辑</el-button
          >
        </span>
      </p>
      <el-row v-if="isNotMix(messageList)">
        <el-col :span="20">
          <message
            :label-width="labelWidth"
            :messageMsg="messageMsg"
            @getMessageMsg="getMessageMsg"
          ></message>
        </el-col>
        <el-col
          :span="2"
          v-if="currentSelect.length > 1"
          ><el-button
            type="text"
            size="mini"
            @click="messageMixShow = true"
            class="mixBtn"
            >混合值查看及编辑</el-button
          ></el-col
        >
      </el-row>
    </div>
    <div
      class="targetingItem"
      v-if="!adTypesHasAssetFeed && objective == 'OUTCOME_SALES'"
    >
      <p
        class="baseName"
        v-if="!isNotMix(titleList)"
      >
        <span class="lebel">标题：</span>
        <span>
          <el-button
            type="text"
            size="mini"
            @click="titleMixShow = true"
            >混合值查看及编辑</el-button
          >
        </span>
      </p>
      <el-row v-if="isNotMix(titleList)">
        <el-col :span="20">
          <title1
            :label-width="labelWidth"
            :titleMsg="titleMsg"
            @getTitleMsg="getTitleMsg"
          ></title1>
        </el-col>
        <el-col
          :span="2"
          v-if="currentSelect.length > 1"
          ><el-button
            type="text"
            size="mini"
            @click="titleMixShow = true"
            class="mixBtn"
            >混合值查看及编辑</el-button
          ></el-col
        >
      </el-row>
    </div>
    <div
      class="targetingItem"
      v-if="!adTypesHasAssetFeed && objective == 'OUTCOME_SALES'"
    >
      <p
        class="baseName"
        v-if="!isNotMix(discriList)"
      >
        <span class="lebel">描述：</span>
        <span>
          <el-button
            type="text"
            size="mini"
            @click="discriMixShow = true"
            >混合值查看及编辑</el-button
          >
        </span>
      </p>
      <el-row v-if="isNotMix(discriList)">
        <el-col :span="20">
          <discri
            :label-width="labelWidth"
            :discriMsg="discriMsg"
            @getDiscriMsg="getDiscriMsg"
          ></discri>
        </el-col>
        <el-col
          :span="2"
          v-if="currentSelect.length > 1"
          ><el-button
            type="text"
            size="mini"
            @click="discriMixShow = true"
            class="mixBtn"
            >混合值查看及编辑</el-button
          ></el-col
        >
      </el-row>
    </div>
    <!-- <div class="targetingItem">
            <p class="baseName" v-if="!isNotMix(discriList)">
                <span class="lebel">描述：</span>
                <span>
                    <el-button type="text" size="mini" @click="discriMixShow = true">混合值查看及编辑</el-button>
                </span>
            </p>
            <el-row  v-if="isNotMix(discriList)" >
                <el-col :span="20">
                    <discri :label-width="labelWidth" :discriMsg="discriMsg" @getDiscriMsg="getDiscriMsg"></discri>
                </el-col>
                <el-col :span="2" v-if="currentSelect.length>1"><el-button type="text" size="mini"  @click="discriMixShow = true" class="mixBtn">混合值查看及编辑</el-button></el-col>
            </el-row>
        </div> -->
    <div
      class="targetingItem"
      v-if="(!adTypesHasAssetFeed && objective == 'OUTCOME_SALES') || (objective == 'POST_ENGAGEMENT' && hasLink)"
    >
      <p
        class="baseName"
        v-if="!isNotMix(urlList)"
      >
        <span class="lebel">网址：</span>
        <span>
          <el-button
            type="text"
            size="mini"
            @click="urlMixShow = true"
            >混合值查看及编辑</el-button
          >
        </span>
      </p>
      <el-row v-if="isNotMix(urlList)">
        <el-col :span="20">
          <url
            :label-width="labelWidth"
            :urlMsg="urlMsg"
            @getUrlMsg="getUrlMsg"
          ></url>
        </el-col>
        <el-col
          :span="2"
          v-if="currentSelect.length > 1"
          ><el-button
            type="text"
            size="mini"
            @click="urlMixShow = true"
            class="mixBtn"
            >混合值查看及编辑</el-button
          ></el-col
        >
      </el-row>
    </div>
    <div
      class="targetingItem"
      v-if="objective == 'OUTCOME_SALES'"
    >
      <p
        class="baseName"
        v-if="!isNotMix(domainList)"
      >
        <span class="lebel">网域：</span>
        <span>
          <el-button
            type="text"
            size="mini"
            @click="domainMixShow = true"
            >混合值查看及编辑</el-button
          >
        </span>
      </p>
      <el-row v-if="isNotMix(domainList)">
        <el-col :span="20">
          <domain
            :label-width="labelWidth"
            :domainMsg="domainMsg"
            @getDomainMsg="getDomainMsg"
          ></domain>
        </el-col>
        <el-col
          :span="2"
          v-if="currentSelect.length > 1"
          ><el-button
            type="text"
            size="mini"
            @click="domainMixShow = true"
            class="mixBtn"
            >混合值查看及编辑</el-button
          ></el-col
        >
      </el-row>
    </div>
    <div
      class="targetingItem"
      v-if="objective == 'OUTCOME_SALES'"
    >
      <p
        class="baseName"
        v-if="!isNotMix(showUrlList)"
      >
        <span class="lebel">显示链接：</span>
        <span>
          <el-button
            type="text"
            size="mini"
            @click="showUrlMixShow = true"
            >混合值查看及编辑</el-button
          >
        </span>
      </p>
      <el-row v-if="isNotMix(showUrlList)">
        <el-col :span="20">
          <show-url
            :label-width="labelWidth"
            :showUrlMsg="showUrlMsg"
            @getShowUrlMsg="getShowUrlMsg"
          ></show-url>
        </el-col>
        <el-col
          :span="2"
          v-if="currentSelect.length > 1"
          ><el-button
            type="text"
            size="mini"
            @click="showUrlMixShow = true"
            class="mixBtn"
            >混合值查看及编辑</el-button
          ></el-col
        >
      </el-row>
    </div>
    <div
      class="targetingItem"
      v-if="!adTypesHasAssetFeed && objective !== 'PAGE_LIKES'"
    >
      <p
        class="baseName"
        v-if="!isNotMix(actionTypeList)"
      >
        <span class="lebel">行动号召：</span>
        <span>
          <el-button
            type="text"
            size="mini"
            @click="actionTypeMixShow = true"
            >混合值查看及编辑</el-button
          >
        </span>
      </p>
      <el-row v-if="isNotMix(actionTypeList)">
        <el-col :span="20">
          <action-type
            :label-width="labelWidth"
            :actionTypeMsg="actionTypeMsg"
            @getActionTypeMsg="getActionTypeMsg"
            :callToActions="callToActions"
          ></action-type>
        </el-col>
        <el-col
          :span="2"
          v-if="currentSelect.length > 1"
          ><el-button
            type="text"
            size="mini"
            @click="actionTypeMixShow = true"
            class="mixBtn"
            >混合值查看及编辑</el-button
          ></el-col
        >
      </el-row>
    </div>
    <div
      class="targetingItem"
      v-if="adTypesHasAssetFeed"
    >
      <p
        class="baseName"
        v-if="!isNotMix(webLinkLists)"
      >
        <span class="lebel">网址：</span>
        <span>
          <el-tooltip
            class="item"
            effect="dark"
            content="网址编辑后将会统一"
            placement="top-start"
          >
            <el-button
              type="text"
              size="mini"
              @click="editMix('editWebLinks')"
              >混合值编辑</el-button
            >
          </el-tooltip>
        </span>
      </p>
      <el-row v-if="webLinkLists.length && (isNotMix(webLinkLists) || editWebLinks)">
        <el-col :span="20">
          <el-form
            ref="ruleForm"
            label-width="140px"
            class="demo-ruleForm"
          >
            <asset-feed-actions
              v-model="webLinkListsMsg"
              @input="getWebLinkListsMsg"
            ></asset-feed-actions>
          </el-form>
        </el-col>
        <!-- <el-col :span="2" v-if="currentSelect.length>1"><el-button type="text" size="mini"  @click="setBase('locations')" class="mixBtn">恢复初始值</el-button></el-col> -->
      </el-row>
    </div>
    <div
      class="targetingItem"
      v-if="adTypesHasAssetFeed"
    >
      <p
        class="baseName"
        v-if="!isNotMix(actionsTypeList)"
      >
        <span class="lebel">行动号召：</span>
        <span>
          <el-tooltip
            class="item"
            effect="dark"
            content="行动号召编辑后将会统一"
            placement="top-start"
          >
            <el-button
              type="text"
              size="mini"
              @click="editMix('editActionsTypes')"
              >混合值编辑</el-button
            >
          </el-tooltip>
        </span>
      </p>
      <el-row v-if="actionsTypeList.length && (isNotMix(actionsTypeList) || editActionsTypes)">
        <el-col :span="20">
          <el-form
            ref="ruleForm"
            label-width="140px"
            class="demo-ruleForm"
          >
            <action-select
              v-model="actionTypeListMsg"
              :initData="$store.getters.initData"
              @input="getActionTypeListMsg"
            ></action-select>
          </el-form>ediaTypeMap
        </el-col>
        <!-- <el-col :span="2" v-if="currentSelect.length>1"><el-button type="text" size="mini"  @click="setBase('locations')" class="mixBtn">恢复初始值</el-button></el-col> -->
      </el-row>
    </div>
    <name-mix
      title="名称"
      :nameMsgList="nameMsgList"
      :nameMixShow.sync="nameMixShow"
      @setMixMsg="setMixMsg"
    ></name-mix>
    <status-mix
      title="状态"
      :statusMsgList="statusMsgList"
      :statusMixShow.sync="statusMixShow"
      @setMixMsg="setMixMsg"
    ></status-mix>
    <page-mix
      :pageMsgList="pageMessageList"
      :pageList="pageList"
      :pageMixShow.sync="pageMixShow"
      @setMixMsg="setMixMsg"
      :account="account"
      :targetUserId="targetUserId"
    ></page-mix>
    <message-mix
      :messageMsgList="messageMsgList"
      :messageMixShow.sync="messageMixShow"
      @setMixMsg="setMixMsg"
    ></message-mix>
    <title-mix
      :titleMsgList="titleMsgList"
      :titleMixShow.sync="titleMixShow"
      @setMixMsg="setMixMsg"
    ></title-mix>
    <discri-mix
      :discriMsgList="discriMsgList"
      :discriMixShow.sync="discriMixShow"
      @setMixMsg="setMixMsg"
    ></discri-mix>
    <url-mix
      title="网址"
      :urlMsgList="urlMsgList"
      :urlMixShow.sync="urlMixShow"
      @setMixMsg="setMixMsg"
    ></url-mix>
    <domain-mix
      :domainMsgList="domainMsgList"
      :domainMixShow.sync="domainMixShow"
      @setMixMsg="setMixMsg"
    ></domain-mix>
    <show-url-mix
      title="显示链接"
      :showUrlMsgList="showUrlMsgList"
      :showUrlMixShow.sync="showUrlMixShow"
      @setMixMsg="setMixMsg"
    ></show-url-mix>
    <action-type-mix
      :actionTypeMsgList="actionTypeMsgList"
      :actionTypeMixShow.sync="actionTypeMixShow"
      @setMixMsg="setMixMsg"
    ></action-type-mix>
    <ad-type
      :adTypeMsg="adTypeMsg"
      @getAdTypeMsg="getAdTypeMsg"
      :adTypeMixShow.sync="adTypeMixShow"
      :objective="objective"
      :targetUserId="targetUserId"
      :account="account"
      :currentSelect="currentSelect"
      @setThumbList="setThumbList"
      :videoMap="videoMap"
    ></ad-type>
    <post-id-mix
      :postIdMsgList="postIdMsgList"
      :postIdMixShow.sync="postIdMixShow"
    ></post-id-mix>
  </div>
</template>
<script>
import page from './page';
import pageMix from './pageMix';
import message from './message';
import messageMix from './messageMix.vue';
import title1 from './title.vue';
import titleMix from './titleMix.vue';
import discri from './discri.vue';
import discriMix from './discriMix.vue';
import url from './url.vue';
import urlMix from './urlMix.vue';
import showUrl from './showUrl.vue';
import showUrlMix from './showUrlMix.vue';
import actionTypeMix from './actionTypeMix.vue';
import actionType from './actionType.vue';
import adType from './adType.vue';
import name from '../campaign/name';
import nameMix from '../campaign/nameMix';
import status from '../campaign/status';
import statusMix from '../campaign/statusMix';
import domain from './domain';
import domainMix from './domainMix';
import postId from './postId.vue';
import postIdMix from './postIdMix.vue';
import { accountPages,querySlideshowVideoBatch} from '@/api/creatAd.js';
import actionTypes from '@/views/adManagement/createAd/components/assetFeed/actionType.vue';
import actionSelect from '@/views/adManagement/createAd/components/assetFeed/actionSelect.vue';
import assetFeedActions from './assetFeedActions.vue';
export default {
  props: ['currentSelect', 'baseData', 'updateList', 'account', 'show_mood', 'targetUserId'],
  data() {
    return {
      labelWidth: '140px',
      listData: JSON.parse(JSON.stringify(this.updateList)),
      pageMsgList: [],
      messageList: [],
      titleList: [],
      discriList: [],
      urlList: [],
      showUrlList: [],
      actionTypeList: [],
      urlPramsList: [],
      adTypeList: [],
      pageMsg: {},
      messageMsg: {},
      titleMsg: {},
      discriMsg: {},
      urlMsg: {},
      showUrlMsg: {},
      actionTypeMsg: {},
      urlPramsMsg: {},
      adTypeMsg: {},
      pageMixShow: false,
      messageMixShow: false,
      titleMixShow: false,
      discriMixShow: false,
      urlMixShow: false,
      showUrlMixShow: false,
      actionTypeMixShow: false,
      adTypeMixShow: false,
      nameMixShow: false,
      statusMixShow: false,
      nameMsg: '',
      statusMsg: '',
      nameList: [],
      statusList: [],
      loadPages: false,
      pageList: [],
      domainList: [],
      domainMsg: {},
      domainMixShow: false,
      // 动态广告
      actionsTypeList: [],
      webLinkLists: [],
      editWebLinks: false,
      editActionsTypes: false,
      webLinkListsMsg: [],
      actionTypeListMsg: [],
      postIdList: [],
      postIdMsg: {},
      postIdMixShow: false,
      videoMap:{},
      timer:null,
      videoUploadId:[],
      cancleGetbatchVideoStatus:false
    };
  },
  components: {
    page,
    pageMix,
    message,
    messageMix,
    title1,
    titleMix,
    discri,
    discriMix,
    url,
    urlMix,
    showUrl,
    showUrlMix,
    actionType,
    actionTypeMix,
    adType,
    name,
    nameMix,
    status,
    statusMix,
    domain,
    domainMix,
    actionTypes,
    actionSelect,
    assetFeedActions,
    postId,
    postIdMix,
  },
  computed: {
    callToActions() {
      let initData = this.$store.getters.initData;
      let business = this.objective == 'OUTCOME_SALES' ? '网站' : 'FACEBOOK_PAGE';
      if (initData && initData.ad) {
        return initData.ad.BusinessType.find((v) => v.key == business).objectives.find((k) => k.key == this.objective)
          .callToActions;
      } else {
        return [];
      }
    },
    hasLink() {
      let action_type = this.actionTypeMsg.action_type || '';
      if (this.callToActions.length && action_type) {
        return this.callToActions.find((v) => v.key == action_type).link;
      } else {
        return false;
      }
    },
    objective() {
      let list = [];
      this.currentSelect.map((v) => {
        this.listData.map((m) => {
          if (m.id == v) {
            list.push(m.objective);
          }
        });
      });
      console.log('-------');
      console.log(list.includes('PAGE_LIKES'));
      console.log(list.includes('POST_ENGAGEMENT'));

      if (list.includes('PAGE_LIKES')) {
        return 'PAGE_LIKES';
      } else if (list.includes('POST_ENGAGEMENT')) {
        return 'POST_ENGAGEMENT';
      } else {
        return 'OUTCOME_SALES';
      }
    },
    // 广告样式
    adTypesHasAssetFeed() {
      console.log('adTypesHasAssetFeed', this.adTypeList.map((v) => v.media_type).includes('asset_feed'));
      return this.adTypeList.map((v) => v.media_type).includes('asset_feed');
    },
    nameMsgList() {
      let list = [];
      this.currentSelect.map((v) => {
        this.listData.map((m) => {
          if (m.id == v) {
            let nameObj = {};
            // 主页
            nameObj.name = m.name || '';
            nameObj.id = m.id;
            list.push(nameObj);
          }
        });
      });
      return list;
    },
    statusMsgList() {
      let list = [];
      this.currentSelect.map((v) => {
        this.listData.map((m) => {
          if (m.id == v) {
            let statusObj = {};
            // 主页
            statusObj.status = m.status || '';
            statusObj.id = m.id;
            statusObj.name = m.name;
            list.push(statusObj);
          }
        });
      });
      return list;
    },
    pageMessageList() {
      let list = [];
      this.currentSelect.map((v) => {
        this.listData.map((m) => {
          if (m.id == v) {
            let pageObj = {};
            // 主页
            pageObj.page_id = m.creative.page_id || '';
            pageObj.instagram_actor_id = m.creative.instagram_actor_id || '';
            pageObj.name = m.name || '';
            pageObj.id = m.id;
            list.push(pageObj);
          }
        });
      });
      return list;
    },
    messageMsgList() {
      let list = [];
      this.currentSelect.map((v) => {
        this.listData.map((m) => {
          if (m.id == v) {
            // 正文
            list.push({ message: m.creative.message || '', name: m.name, id: m.id });
          }
        });
      });
      return list;
    },
    postIdMsgList() {
      let list = [];
      this.currentSelect.map((v) => {
        this.listData.map((m) => {
          if (m.id == v) {
            // 帖子Id
            list.push({ post_id: m.creative.post_id || '', name: m.name, id: m.id });
          }
        });
      });
      return list;
    },
    titleMsgList() {
      let list = [];
      this.currentSelect.map((v) => {
        this.listData.map((m) => {
          if (m.id == v) {
            // 标题
            list.push({ title: m.creative.title || '', name: m.name, id: m.id });
          }
        });
      });
      return list;
    },
    discriMsgList() {
      let list = [];
      this.currentSelect.map((v) => {
        this.listData.map((m) => {
          if (m.id == v) {
            // 描述
            list.push({ content: m.creative.content || '', name: m.name, id: m.id });
          }
        });
      });
      return list;
    },
    urlMsgList() {
      let list = [];
      this.currentSelect.map((v) => {
        this.listData.map((m) => {
          if (m.id == v) {
            // 网址
            list.push({ web_url: m.creative.web_url || '', name: m.name, id: m.id });
          }
        });
      });
      return list;
    },
    domainMsgList() {
      let list = [];
      this.currentSelect.map((v) => {
        this.listData.map((m) => {
          if (m.id == v) {
            // 网址
            list.push({ domain: m.conversion_domain || '', name: m.name, id: m.id });
          }
        });
      });
      return list;
    },
    showUrlMsgList() {
      let list = [];
      this.currentSelect.map((v) => {
        this.listData.map((m) => {
          if (m.id == v) {
            // 显示链接
            list.push({ show_url: m.creative.show_url || '', name: m.name, id: m.id });
          }
        });
      });
      return list;
    },
    actionTypeMsgList() {
      let list = [];
      this.currentSelect.map((v) => {
        this.listData.map((m) => {
          if (m.id == v) {
            // 行动号召
            list.push({ action_type: m.creative.action_type || '', name: m.name, id: m.id });
          }
        });
      });
      return list;
    },
    // videoUploadId(){
    //   console.log(this.listData.filter((v)=>v.creative.media_type == 'video' && v.creative.videoStatus != 'ready'));
      
    // }
  },

  methods: {
    setThumbList(key,value){
      this.videoMap[key] = JSON.parse(JSON.stringify(value))
    },
    // 编辑混合值
    editMix(v) {
      this[v] = true;
    },
    // 判断是否是混合值
    isNotMix(list) {
      return !list.some((v) => {
        return JSON.stringify(v) != JSON.stringify(list[0]);
      });
    },
    emitData() {
      this.$emit('setAdData', this.listData);
    },
    async getAccountPage(v) {
      //console.log(v);
      this.loadPages = true;
      await accountPages({ accountId: this.account, force: v }, this.targetUserId).then((res) => {
        this.loadPages = false;
        let canUse = res.data.filter((v) => v.is_published);
        let notUse = res.data.filter((v) => !v.is_published);
        this.pageList = canUse.concat(notUse);
        if (v) {
          this.$message('更新成功');
        }
      });
    },
    // 获取主页
    getPages(v) {
      //console.log('ssssssss');
      this.getAccountPage(v);
    },
    // 设置每一项的列表
    setList() {
      let pageMsgList = [],
        messageList = [],
        titleList = [],
        discriList = [],
        urlList = [],
        showUrlList = [],
        actionTypeList = [],
        urlPramsList = [],
        adTypeList = [],
        nameList = [],
        statusList = [],
        domainList = [],
        webLinkLists = [],
        actionsTypeList = [],
        postIdList = [];
      this.currentSelect.map((v) => {
        this.listData.map((m) => {
          if (m.id == v) {
            // 名称
            nameList.push(m.name);
            // 状态
            statusList.push(m.status);
            let pageObj = {};
            // 主页
            pageObj.page_id = m.creative.page_id || '';
            pageObj.instagram_actor_id = m.creative.instagram_actor_id || '';
            pageMsgList.push(pageObj);
            // 正文
            messageList.push({ message: m.creative.message });
            // 标题
            titleList.push({ title: m.creative.title });
            // 描述
            discriList.push({ content: m.creative.content });
            // 网址
            urlList.push({ web_url: m.creative.web_url });
            // 网域
            domainList.push({ domain: m.conversion_domain });
            // 显示链接
            showUrlList.push({ show_url: m.creative.show_url });
            // 行动号召
            actionTypeList.push({ action_type: m.creative.action_type });
            // 帖子Id
            postIdList.push({ post_id: m.creative.post_id });
            // 广告类型
            let adObj = {};
            adObj.media_type = m.creative.media_type;
            // 视频
            adObj.videoStatus = m.creative.videoStatus;
            adObj.videoId = m.creative.videoId;
            if (m.creative.media_type == 'video') {
              adObj.image = m.creative.image;
              adObj.thumbUrl = m.creative.thumbUrl;
              adObj.source_id = m.creative.source_id;
            } else if (m.creative.media_type == 'image') {
              adObj.image = m.creative.image;
              adObj.source_id = m.creative.source_id;
              
            } else if (m.creative.media_type == 'post') {
              adObj.post_id = m.creative.post_id;
            } else if (m.creative.media_type == 'muti') {
              adObj.mutiImgs = [...m.creative.mutiImgs];
            } else if (m.creative.media_type == 'asset_feed') {
              let { optimizationType, actionTypes, linkUrls, ...fbAssetFeedSpec } = m.creative.fbAssetFeedSpec;
              adObj.fbAssetFeedSpec = fbAssetFeedSpec;
            }
            adTypeList.push(adObj);
            //console.log(adTypeList);
            //console.log(this.isNotMix(adTypeList));
            // 若为动态广告
            if (m.creative.fbAssetFeedSpec) {
              m.creative.fbAssetFeedSpec.linkUrls = m.creative.fbAssetFeedSpec.linkUrls;
              webLinkLists.push(m.creative.fbAssetFeedSpec.linkUrls || ['']);
              actionsTypeList.push(m.creative.fbAssetFeedSpec.actionTypes || ['']);
            }

            // 网址参数
            // urlPramsList.push({action_type:m.creative.action_type,name:m.name,id:m.id})
          }
        });
      });
      this.pageMsgList = pageMsgList;
      this.messageList = messageList;
      this.titleList = titleList;
      this.discriList = discriList;
      this.urlList = urlList;
      this.showUrlList = showUrlList;
      this.actionTypeList = actionTypeList;
      this.adTypeList = adTypeList;
      this.nameList = nameList;
      this.statusList = statusList;
      this.domainList = domainList;
      this.webLinkLists = webLinkLists;
      this.actionsTypeList = actionsTypeList;
      this.postIdList = postIdList;
      // this.urlPramsList = urlPramsList;
    },
    setMsg() {
      this.setPageMsg();
      this.setMessageMsg();
      this.setTitleMsg();
      this.setDiscriMsg();
      this.setUrlMsg();
      this.setShowUrlMsg();
      this.setActionTypeMsg();
      this.setAdTypeMsg();
      this.setNameMsg();
      this.setStatusMsg();
      this.setDomainMsg();
      this.setActionTypeListMsg();
      this.setWebLinkListMsg();
      this.setDomainMsg();
      this.setPostIdMsg();
    },
    setPageMsg() {
      this.pageMsg = this.isNotMix(this.pageMsgList)
        ? { page_id: this.pageMsgList[0].page_id, instagram_actor_id: this.pageMsgList[0].instagram_actor_id }
        : { page_id: '', instagram_actor_id: '' };
    },
    setMessageMsg() {
      this.messageMsg = this.isNotMix(this.messageList) ? { message: this.messageList[0].message } : { message: '' };
    },
    setTitleMsg() {
      this.titleMsg = this.isNotMix(this.titleList) ? { title: this.titleList[0].title } : { title: '' };
    },
    setDiscriMsg() {
      this.discriMsg = this.isNotMix(this.discriList) ? { content: this.discriList[0].content } : { content: '' };
    },
    setUrlMsg() {
      this.urlMsg = this.isNotMix(this.urlList) ? { web_url: this.urlList[0].web_url } : { web_url: '' };
    },
    setShowUrlMsg() {
      this.showUrlMsg = this.isNotMix(this.showUrlList) ? { show_url: this.showUrlList[0].show_url } : { show_url: '' };
    },
    setActionTypeMsg() {
      this.actionTypeMsg = this.isNotMix(this.actionTypeList)
        ? { action_type: this.actionTypeList[0].action_type }
        : { action_type: '' };
    },
    setAdTypeMsg() {
      this.adTypeMsg = this.isNotMix(this.adTypeList)
        ? { ...this.adTypeList[0] }
        : { media_type: 'image', image: '', source_id: '' };
    },
    setDomainMsg() {
      this.domainMsg = this.isNotMix(this.domainList) ? { ...this.domainList[0] } : { domain: '' };
    },
    setActionTypeListMsg() {
      this.actionTypeListMsg = this.isNotMix(this.actionsTypeList) ? this.actionsTypeList[0] : [''];
      this.editActionsTypes = false;
    },
    setWebLinkListMsg() {
      this.webLinkListsMsg = this.isNotMix(this.webLinkLists) ? this.webLinkLists[0] : [''];
      this.editWebLinks = false;
    },
    setPostIdMsg() {
      this.postIdMsg = this.isNotMix(this.postIdList) ? { ...this.postIdList[0] } : { post_id: '' };
    },
    getPageMsg(val) {
      if (JSON.stringify(this.pageMsg) == JSON.stringify(val)) return;
      this.currentSelect.map((v) => {
        this.listData.map((m, i) => {
          if (m.id == v) {
            m.creative.page_id = val.page_id;
            m.creative.instagram_actor_id = val.instagram_actor_id;
            this.$set(this.listData, i, m);
          }
        });
      });
      this.listData = JSON.parse(JSON.stringify(this.listData));
      this.setList();
      this.emitData();
      // this.pageMsg = JSON.parse(JSON.stringify(val))
    },
    getMessageMsg(val) {
      if (JSON.stringify(this.messageMsg) == JSON.stringify(val)) return;
      this.currentSelect.map((v) => {
        this.listData.map((m, i) => {
          if (m.id == v) {
            m.creative.message = val.message;
            this.$set(this.listData, i, m);
          }
        });
      });
      this.listData = JSON.parse(JSON.stringify(this.listData));
      this.setList();
      this.emitData();
    },
    getTitleMsg(val) {
      if (JSON.stringify(this.titleMsg) == JSON.stringify(val)) return;
      this.currentSelect.map((v) => {
        this.listData.map((m, i) => {
          if (m.id == v) {
            m.creative.title = val.title;
            this.$set(this.listData, i, m);
          }
        });
      });
      this.listData = JSON.parse(JSON.stringify(this.listData));
      this.setList();
      this.emitData();
    },
    getDiscriMsg(val) {
      if (JSON.stringify(this.discriMsg) == JSON.stringify(val)) return;
      this.currentSelect.map((v) => {
        this.listData.map((m, i) => {
          if (m.id == v) {
            m.creative.content = val.content;
            this.$set(this.listData, i, m);
          }
        });
      });
      this.listData = JSON.parse(JSON.stringify(this.listData));
      this.setList();
      this.emitData();
    },
    getUrlMsg(val) {
      if (JSON.stringify(this.urlMsg) == JSON.stringify(val)) return;
      this.currentSelect.map((v) => {
        this.listData.map((m, i) => {
          if (m.id == v) {
            m.creative.web_url = val.web_url;
            this.$set(this.listData, i, m);
          }
        });
      });
      this.listData = JSON.parse(JSON.stringify(this.listData));
      this.setList();
      this.emitData();
    },
    getDomainMsg(val) {
      if (JSON.stringify(this.domainMsg) == JSON.stringify(val)) return;
      this.currentSelect.map((v) => {
        this.listData.map((m, i) => {
          if (m.id == v) {
            m.conversion_domain = val.domain;
            this.$set(this.listData, i, m);
          }
        });
      });
      this.listData = JSON.parse(JSON.stringify(this.listData));
      this.setList();
      this.emitData();
    },
    getShowUrlMsg(val) {
      if (JSON.stringify(this.showUrlMsg) == JSON.stringify(val)) return;

      this.currentSelect.map((v) => {
        this.listData.map((m, i) => {
          if (m.id == v) {
            m.creative.show_url = val.show_url;
            this.$set(this.listData, i, m);
          }
        });
      });
      this.listData = JSON.parse(JSON.stringify(this.listData));
      this.setList();
      this.emitData();
    },
    getActionTypeMsg(val) {
      if (JSON.stringify(this.actionTypeMsg) == JSON.stringify(val)) return;
      this.currentSelect.map((v) => {
        this.listData.map((m, i) => {
          if (m.id == v) {
            m.creative.action_type = val.action_type;
            this.$set(this.listData, i, m);
          }
        });
      });
      this.listData = JSON.parse(JSON.stringify(this.listData));
      this.setList();
      this.setActionTypeMsg();
      this.emitData();
    },
    getAdTypeMsg(val) {
      console.log(val)
      // if (JSON.stringify(this.adTypeMsg) == JSON.stringify(val)) return;
      this.currentSelect.map((v) => {
        this.listData.map((m, i) => {
          if (m.id == v) {
            let fbAssetFeedSpec = m.creative.fbAssetFeedSpec;
            let creative = { ...m.creative, ...val };
            if (val.media_type == 'asset_feed') {
              creative.media_type = 'asset_feed';
              creative.fbAssetFeedSpec = val.fbAssetFeedSpec ? { ...fbAssetFeedSpec, ...val.fbAssetFeedSpec } : null;
            }
            console.log(creative);
            m.creative = JSON.parse(JSON.stringify(creative));
          }
        });
      });
      this.listData = JSON.parse(JSON.stringify(this.listData));
      
      this.setList();
      this.setAdTypeMsg();
      this.emitData();
      this.$nextTick(()=>{
        this.getVideoStatus();
      })
      
    },
    setNameMsg() {
      this.nameMsg = this.isNotMix(this.nameList) ? this.nameList[0] : '';
    },
    getNameMsg(val) {
      if (JSON.stringify(this.nameMsg) == JSON.stringify(val)) return;
      this.currentSelect.map((v) => {
        this.listData.map((m, i) => {
          if (m.id == v) {
            m.name = val;
            this.$set(this.listData, i, m);
            this.$emit('setBaseName', 'ad', v, val);
          }
        });
      });
      this.listData = JSON.parse(JSON.stringify(this.listData));
      this.setList();
      this.emitData();
    },
    setStatusMsg() {
      this.statusMsg = this.isNotMix(this.statusList) ? this.statusList[0] : 'PAUSED';
    },
    getStatusMsg(val) {
      if (JSON.stringify(this.statusMsg) == JSON.stringify(val)) return;
      this.currentSelect.map((v) => {
        this.listData.map((m, i) => {
          if (m.id == v) {
            m.status = val;
            this.$set(this.listData, i, { ...m });
          }
        });
      });
      this.listData = JSON.parse(JSON.stringify(this.listData));
      this.setList();
      this.setStatusMsg();
      this.emitData();
    },
    getActionTypeListMsg(val) {
      // if(JSON.stringify(this.actionTypeListMsg) == JSON.stringify(val)) return;
      this.currentSelect.map((v) => {
        this.listData.map((m, i) => {
          if (m.id == v) {
            m.creative.fbAssetFeedSpec.actionTypes = val;
            this.$set(this.listData, i, m);
          }
        });
      });
      this.listData = JSON.parse(JSON.stringify(this.listData));
      this.setList();
      this.emitData();
    },
    getWebLinkListsMsg(val) {
      // if(JSON.stringify(this.webLinkListsMsg) == JSON.stringify(val)) return;
      this.currentSelect.map((v) => {
        this.listData.map((m, i) => {
          if (m.id == v) {
            m.creative.fbAssetFeedSpec.linkUrls = val;
            this.$set(this.listData, i, m);
          }
        });
      });
      this.listData = JSON.parse(JSON.stringify(this.listData));
      this.setList();
      this.emitData();
    },
    //接收混合参数
    setMixMsg(type, list) {
      if (!list) return;
      list.map((v) => {
        this.listData.map((k, i) => {
          if (v.id == k.id) {
            if (type == 'page') {
              k.creative.page_id = v.page_id;
              k.creative.instagram_actor_id = v.instagram_actor_id;
            }
            if (type == 'message') {
              k.creative.message = v.message;
            }
            if (type == 'title') {
              k.creative.title = v.title;
            }
            if (type == 'content') {
              k.creative.content = v.content;
            }
            if (type == 'url') {
              k.creative.web_url = v.web_url;
            }
            if (type == 'showUrl') {
              k.creative.show_url = v.show_url;
            }
            if (type == 'actionType') {
              k.creative.action_type = v.action_type;
            }
            if (type == 'name') {
              k.name = v.name;
              //console.log('object--------------------------');
              this.$emit('setBaseName', 'ad', v.id, v.name);
              //console.log('===========================');
            }
            if (type == 'status') {
              k.status = v.status;
            }
            if (type == 'domain') {
              k.conversion_domain = v.domain;
            }
          }
          this.$set(this.listData, i, k);
        });
      });
      this.setList();
      this.emitData();
      if (type == 'page') this.setPageMsg();
      if (type == 'message') this.setMessageMsg();
      if (type == 'title') this.setTitleMsg();
      if (type == 'content') this.setDiscriMsg();
      if (type == 'url') this.setUrlMsg();
      if (type == 'showUrl') this.setShowUrlMsg();
      if (type == 'actionType') this.setActionTypeMsg();
      if (type == 'name') this.setNameMsg();
      if (type == 'status') this.setStatusMsg();
      if (type == 'domain') this.setDomainMsg();
    },
    setBaseData(type) {
      this.currentSelect.map((v) => {
        let list = this.baseData;
        let baseMsg = list.filter((k) => k.id == v) ? list.filter((k) => k.id == v)[0] : null;
        if (!baseMsg) {
          return;
        }
        let msg = JSON.parse(JSON.stringify(baseMsg));
        this.listData.map((m, i) => {
          if (m.id == v) {
            if (type == 'adType') {
              m.creative.media_type = msg.creative.media_type;
              m.creative.image = msg.creative.image;
              m.creative.source_id = msg.creative.source_id;
              m.creative.mutiImgs =
                msg.mutiImgs && msg.creative.mutiImgs.length ? msg.creative.mutiImgs.filter((z) => z.image) : null;
              m.creative.post_id = msg.creative.post_id;
            } else if (type == 'actionsType') {
              m.creative.fbAssetFeedSpec.actionTypes = msg.creative.fbAssetFeedSpec.actionTypes;
            } else if (type == 'links') {
              m.creative.fbAssetFeedSpec.linkUrls = msg.creative.fbAssetFeedSpec.linkUrls;
            }
          }
          this.$set(this.listData, i, JSON.parse(JSON.stringify(m)));
        });
      });
      this.emitData();
      this.setList();
      this.setAdTypeMsg();
    },
    getVideoIds(){
      console.log(this.listData);
      console.log(this.updateList)
      let list = this.updateList.filter((v)=>v.creative.media_type == 'video' && v.creative.videoStatus != 'ready')
      this.videoUploadId = list.map(k=>{
        return {
          videoId:k.creative.videoId,
          accountId:this.account,
          id:k.id
        }
      }).filter(k=>k.videoId)
    },
    getVideoStatus(){
      this.getVideoIds();
      if(!this.videoUploadId.length) return;
      this.timer = null;
      // return 
      console.log(this.cancleGetbatchVideoStatus)
      if(this.cancleGetbatchVideoStatus) return;
      querySlideshowVideoBatch(this.videoUploadId).then(res=>{
        console.log(res);
        res.data.forEach((items)=>{
          if(items.videoStatus == 'ready'){
            this.setThumbList(items.videoId,items.thumbnails)
          }
          this.listData.forEach((item,index)=>{
            if(items.videoId == item.creative.videoId){
              item.creative.videoStatus = items.videoStatus;
              item.creative.media_type = 'video';
            }
            this.$set(this.listData, index, JSON.parse(JSON.stringify(item)));
          })
        })
        this.setList();
        this.setAdTypeMsg();
        this.emitData();
        this.$nextTick(()=>{
          this.timer = setTimeout(() => {
            this.getVideoStatus();
          }, 10000);
        })
      })
    },
  },
  watch: {
    currentSelect: {
      handler() {
        this.setList();
        this.setMsg();
        //console.log(this.actionTypeMsg);
      },
      deep: true,
    },
    updateList: {
      handler() {
        this.listData = JSON.parse(JSON.stringify(this.updateList));
        // this.setList();
        // this.setMsg()
      },
      deep: true,
      immediate: true,
    },
    account: {
      handler() {
        this.getAccountPage();
      },
      immediate: true,
    },
    videoUploadId:{
      handler(){
        if(!this.timer){
          // this.getVideoStatus();
        }
        
      },
      deep:true,
    }
  },
  created() {
    this.listData = JSON.parse(JSON.stringify(this.updateList)); 
    this.setList();
    this.setMsg();
  },
};
</script>
<style lang="scss" scope>
.targetingItem {
  margin-bottom: 10px;
  .baseName {
    display: flex;
    align-items: center;
    .lebel {
      flex: 140px 0 0;
      text-align: right;
      padding-right: 12px;
    }
  }
}
</style>
